import React from 'react'
import styled from 'styled-components'
import { buildImageObj, imageUrlFor } from '../helpers'

export function AuthorList({ items }) {
  return (
    <Container>
      <List>
        {items.map(({ author, _key }) => {
          const authorName = author && author.name
          return (
            author && (
              <ListItem key={_key}>
                <div>
                  <Avatar>
                    {author.image && author.image.asset && (
                      <img
                        src={imageUrlFor(buildImageObj(author.image))
                          .width(100)
                          .height(100)
                          .fit('crop')
                          .url()}
                        alt={authorName}
                      />
                    )}
                  </Avatar>
                </div>
                <div>
                  <div>{authorName}</div>
                </div>
              </ListItem>
            )
          )
        })}
      </List>
    </Container>
  )
}

const Container = styled.div`
  justify-self: right;
`
const List = styled.ul`
  list-style: none;
  margin: 1rem 0 !important;
  padding: 0;
`
const ListItem = styled.li`
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div:last-child {
    flex: 1;
    margin-left: 0.5rem;
  }
`

const Avatar = styled.div`
  position: relative;
  width: 2em;
  height: 2em;
  background: #e7ebed;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    vertical-align: top;
    object-fit: cover;
  }
`
